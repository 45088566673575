import Modal from './modules/modal.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite
//import { gsap } from '../gulp/node_modules/gsap/gsap-core.js';
//import { ScrollTrigger } from '../gulp/node_modules/gsap/ScrollTrigger.js';
import { CSSRulePlugin } from '../gulp/node_modules/gsap/CSSRulePlugin.js';
import animations from './modules/animations.js';

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	mobileMenu();
	animations();
}

jQuery(document).ready(function ($) {
	init();
});

//------------------------------------//
// DELETE ACTIVE STATE
//------------------------------------//
const removeActive = function (selector) {
	[...document.querySelectorAll(selector)].forEach(
		(el) => delete el.dataset.active
	);
};

//------------------------------------//
// TABS
//------------------------------------//

const tabTriggers = document.querySelectorAll('.tabs-nav span');

[...tabTriggers].forEach((trigger) => {
	trigger.addEventListener('click', switchTab);
});

function switchTab(e) {
	let targetTab = e.currentTarget.dataset.tab;

	removeActive('.tab[data-active]');
	removeActive('.tabs-nav button[data-active]');

	e.currentTarget.dataset.active = true;
	document.querySelector(`.tab[data-tab='${targetTab}']`).dataset.active = true;
}

//------------------------------------//
// ACCORDION
//------------------------------------//

const accordionTriggers = document.querySelectorAll(
	'.item-list[data-list="accordion"] .item-trigger'
);

[...accordionTriggers].forEach((trigger) => {
	trigger.addEventListener('click', switchItem);
});

function switchItem(e) {
	removeActive('.item-list[data-list="accordion"] .item[data-active]');
	e.currentTarget.parentNode.dataset.active = true;
}
