//Site header
const animateSiteHeader = () => {
	let tl = gsap.timeline();

	tl.from(
		document.querySelectorAll('.site-header > *:not(.site-nav'),
		1,
		{
			opacity: 0,
			stagger: 0.2,
			ease: 'Power2.easeInOut'
		},
		'+=.4'
	);
};

// Animating Content section with image
const animateContentImg = () => {
	const trigger = gsap.utils.toArray('[data-animate="content-img"]');

	if (!trigger) {
		return;
	}

	trigger.forEach((item, index) => {
		//timeline
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				start: 'top 65%',
				end: 'bottom bottom'
				//markers: true
				//scrub: true
			}
		});

		//imgReveal
		tl.to(item.querySelector('span.reveal'), 1, {
			scaleX: 0,
			ease: 'Power2.easeInOut'
		}).from(
			item.querySelector('.img-block img'),
			1.1,
			{
				scale: 1.6,
				ease: 'Power2.easeInOut'
			},
			'-=1'
		);

		if (!item.querySelector('.content-block')) {
			return;
		}

		//contentReveal
		tl.from(
			item.querySelectorAll('.content-block > *'),
			0.5,
			{
				opacity: 0,
				y: 100,
				stagger: 0.15,
				ease: 'Power1.easeInOut'
			},
			'-=.5'
		);
	});
};

// Animating Content section with image
const animateContentContact = () => {
	const trigger = document.querySelector('[data-animate="contact"]');

	if (!trigger) {
		return;
	}

	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: trigger,
			start: 'top 75%',
			end: 'bottom bottom'
			//markers: true
			//scrub: true
		}
	});

	//contentReveal
	tl.from(trigger.querySelectorAll('.content-block > *'), 0.5, {
		opacity: 0,
		x: 100,
		stagger: 0.15,
		ease: 'Power1.easeInOut'
	});

	tl.from(
		trigger.querySelectorAll('.gform_fields .gfield:not(.g-spacing)'),
		0.5,
		{
			opacity: 0,
			x: 100,
			stagger: 0.15,
			ease: 'Power1.easeInOut'
		},
		'-=.75'
	);

	tl.from(
		trigger.querySelectorAll('.gform_footer '),
		0.5,
		{
			opacity: 0,
			x: 100,
			stagger: 0.15,
			ease: 'Power1.easeInOut'
		},
		'-=.75'
	);
};

// Animating services section
const animateServices = () => {
	const trigger = document.querySelector('[data-animate="services"]');

	if (!trigger) {
		return;
	}

	//timeline
	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: trigger,
			start: 'top 65%',
			end: 'bottom bottom'
			//scrub: true
		}
	});

	//contentReveal
	tl.from(trigger.querySelectorAll('.content-block > *'), 0.5, {
		opacity: 0,
		x: 100,
		stagger: 0.15,
		ease: 'Power1.easeInOut'
	});

	//contentReveal
	tl.from(
		trigger.querySelectorAll('.item'),
		0.5,
		{
			opacity: 0,
			stagger: 0.15,
			ease: 'Power1.easeInOut'
		},
		'-=.75'
	);

	tl.from(
		trigger.querySelectorAll('.item h3'),
		0.5,
		{
			opacity: 0,
			x: 100,
			stagger: 0.15,
			ease: 'Power1.easeInOut'
		},
		'-=.5'
	);
};

// Content section (guarantee)
const animateContent = () => {
	const trigger = document.querySelector('[data-animate="content"]');

	if (!trigger) {
		return;
	}

	//timeline
	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: trigger,
			start: 'top 65%',
			end: 'bottom bottom'
			//scrub: true
		}
	});

	//contentReveal
	tl.from(trigger.querySelectorAll('.content-block > *'), 0.5, {
		opacity: 0,
		y: 100,
		stagger: 0.15,
		ease: 'Power1.easeInOut'
	});
};

// Content section (guarantee)
const animateContentList = () => {
	const trigger = document.querySelector('[data-animate="content-list"]');

	if (!trigger) {
		return;
	}

	//timeline
	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: trigger,
			start: 'top 65%',
			end: 'bottom bottom'
			//scrub: true
		}
	});

	//contentReveal
	tl.from(trigger.querySelectorAll('.content-block > *'), 0.5, {
		opacity: 0,
		x: 100,
		stagger: 0.15,
		ease: 'Power1.easeInOut'
	});

	tl.from(
		trigger.querySelectorAll('.content-block ul li'),
		0.5,
		{
			opacity: 0,
			x: 100,
			stagger: 0.15,
			ease: 'Power1.easeInOut'
		},
		'-=.75'
	);
};

// Animating services section
const animateContentMethod = () => {
	const trigger = document.querySelector('[data-animate="method"]');

	if (!trigger) {
		return;
	}

	//timeline
	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: trigger,
			start: 'top 65%',
			end: 'bottom bottom'
			//scrub: true
		}
	});

	//contentReveal
	tl.from(trigger.querySelectorAll('.content-block > *'), 0.5, {
		opacity: 0,
		x: 100,
		stagger: 0.15,
		ease: 'Power1.easeInOut'
	});

	//contentReveal
	tl.from(
		trigger.querySelectorAll('.item'),
		0.5,
		{
			opacity: 0,
			stagger: 0.15,
			ease: 'Power1.easeInOut'
		},
		'-=.75'
	);

	tl.from(
		trigger.querySelectorAll('.item .item-trigger'),
		0.5,
		{
			opacity: 0,
			x: 100,
			stagger: 0.15,
			ease: 'Power1.easeInOut'
		},
		'-=1'
	);

	tl.from(
		trigger.querySelectorAll('.item .item-content'),
		0.5,
		{
			opacity: 0,
			x: 100,
			stagger: 0.15,
			ease: 'Power1.easeInOut'
		},
		'-=1'
	);
};

const animateRefs = () => {
	const trigger = document.querySelector('[data-animate="refs"]');

	if (!trigger) {
		return;
	}

	//timeline
	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: trigger,
			start: 'top 65%',
			end: 'bottom bottom'
			//scrub: true
		}
	});

	//contentReveal
	tl.from(trigger.querySelectorAll('.content-block > *'), 0.5, {
		opacity: 0,
		x: 100,
		stagger: 0.15,
		ease: 'Power1.easeInOut'
	});

	const cardList = gsap.utils.toArray('[data-animate="refs"] .card');

	cardList.forEach((card, index) => {
		const tlCard = gsap.timeline({
			scrollTrigger: {
				trigger: card,
				start: 'top 75%',
				end: 'bottom bottom'
				//scrub: true
			}
		});

		//imgReveal
		tlCard.to(card.querySelector('span.reveal'), 1, {
			scaleX: 0,
			ease: 'Power2.easeInOut'
		});

		tlCard.from(
			card.querySelector('[data-title]'),
			1,
			{
				opacity: 0,
				x: 100,
				ease: 'Power2.easeInOut'
			},
			'-=.75'
		);

		tlCard.from(
			card.querySelector('.card-content span'),
			1,
			{
				opacity: 0,
				x: 100,
				ease: 'Power2.easeInOut'
			},
			'-=.75'
		);
		// .from(
		// 	card.querySelector('.img-block img'),
		// 	1.1,
		// 	{
		// 		scale: 1.6,
		// 		ease: 'Power2.easeInOut'
		// 	},
		// 	'-=1'
		// );
	});
};

const animateGallery = () => {
	const trigger = document.querySelector('[data-animate="gallery"]');

	if (!trigger) {
		return;
	}

	const cardList = gsap.utils.toArray('[data-animate="gallery"] .img-block');

	cardList.forEach((card, index) => {
		const tlCard = gsap.timeline({
			scrollTrigger: {
				trigger: card,
				start: 'top 75%',
				end: 'bottom bottom'
				//scrub: true
			}
		});

		//imgReveal
		tlCard.to(card.querySelector('span.reveal'), 1, {
			scaleY: 0,
			ease: 'Power2.easeInOut'
		});
		// .from(
		// 	card.querySelector('.img-block img'),
		// 	1.1,
		// 	{
		// 		scale: 1.6,
		// 		ease: 'Power2.easeInOut'
		// 	},
		// 	'-=1'
		// );
	});
};

const animations = () => {
	const container = document.querySelector('.site-wrapper');
	const tl = gsap
		.timeline()
		.to(container, { duration: 0, '--visibility': 'visible' });

	animateSiteHeader();
	animateServices();
	animateContentImg();
	animateContent();
	animateContentList();
	animateContentContact();
	animateContentMethod();
	animateRefs();
	animateGallery();
};

export default animations;
